import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap, tap, ignoreElements } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { ConsumerProfileService } from '../../../../../api/bff/services/consumer-profile.service'
import { ConsumerProfileSessionInfo } from '../../../../../domain/consumer/consumer-profile/consumer-profile-session-token.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { Action } from '../../../../../store/root.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { EventsActions } from '../../../../app/modules/events/store/events.actions'
import { ConsumerSessionActions } from '../../consumer-session/store/consumer-session.actions'
import { ConsumerSessionStore } from '../../consumer-session/store/consumer-session.store'
import { MerchantSelectionActions } from '../../merchant-selection/store/merchant-selection.actions'
import { ConsumerProfileActions } from './consumer-profile.actions'
import { OffersService } from '../../../../../api/bff/services/offers.service'
import { clearReferrerFromSessionStorage } from '../../../../app/components/initializer/types/referrer.function'
import { clearEventIdFromSessionStorage } from '../../../../app/components/initializer/types/event-id.functions'
import { clearCampaignIdFromSessionStorage } from '../../../../app/components/initializer/types/campaign-id.functions'
import { ConsumerAssetSubtypeStepActions } from '../../consumer-workflow/modules/consumer-asset-subtype-step/store/consumer-asset-subtype-step.actions'

const createConsumerProfileEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfile)),
    switchMap((action) =>
      new ConsumerProfileService().createConsumerProfile(action.payload).pipe(
        map((result: ConsumerProfileSessionInfo) => {
          return ConsumerProfileActions.createConsumerProfileSuccess(result)
        }),
        catchError((err: HttpError) =>
          of(ConsumerProfileActions.createConsumerProfileFailure(err))
        )
      )
    )
  )

const createConsumerProfileWithAssetEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfileWithAsset)),
    switchMap((action) =>
      new ConsumerProfileService()
        .createConsumerProfileWithAsset(action.payload)
        .pipe(
          map((result: ConsumerProfileSessionInfo) => {
            return ConsumerProfileActions.createConsumerProfileSuccess(result)
          }),
          catchError((err: HttpError) =>
            of(ConsumerProfileActions.createConsumerProfileFailure(err))
          )
        )
    )
  )

const setConsumerProfileEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(ConsumerSessionActions.fetchConsumerSessionSuccess)),
    map((action) =>
      ConsumerProfileActions.setConsumerProfile(action.payload.consumerProfile)
    )
  )

const setConsumerProfileAfterApplicationReassignmentEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.reassignConsumerProfileApplicationMerchantSuccess
      )
    ),
    map((action) => ConsumerProfileActions.setConsumerProfile(action.payload))
  )

const submitConsumerProfileEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.submitConsumerProfile)),
    switchMap((action) =>
      new ConsumerProfileService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .submitConsumerProfile(action.payload)
        .pipe(
          map(() => {
            return ConsumerProfileActions.submitConsumerProfileSuccess(
              action.payload
            )
          }),
          catchError((err: HttpError) =>
            of(ConsumerProfileActions.submitConsumerProfileFailure(err))
          )
        )
    )
  )

const savePreApprovalResultToConsumerProfileEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(EventsActions.preApprovalResult)),
    map((action) =>
      ConsumerProfileActions.savePreApprovalResult(action.payload)
    )
  )

const saveViewedLoanOffersPageEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.setLoanOfferPageViewed)),
    switchMap(() =>
      new OffersService(ConsumerSessionStore.getSessionToken(state$.value))
        .saveLoanOfferPageViewed()
        .pipe(
          map(() => ConsumerProfileActions.setLoanOfferPageViewedSuccess()),
          catchError((error: HttpError) =>
            of(ConsumerProfileActions.submitConsumerProfileFailure(error))
          )
        )
    )
  )

const clearConsumerProfileSessionEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(
      isActionOf([
        ConsumerProfileActions.createConsumerProfileSuccess,
        ConsumerProfileActions.submitConsumerProfileSuccess
      ])
    ),
    tap(() => {
      clearReferrerFromSessionStorage()
      clearEventIdFromSessionStorage()
      clearCampaignIdFromSessionStorage()
    }),
    ignoreElements()
  )

const saveConsumerAssetSubtypeEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(
      isActionOf(
        ConsumerAssetSubtypeStepActions.saveConsumerAssetSubtypeIdSuccess
      )
    ),
    map((action) =>
      ConsumerProfileActions.setConsumerAssetSubtype(action.payload)
    )
  )

const fetchVendorDetailsEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf(
        ConsumerProfileActions.fetchVendorDetails
      )
    ),
    switchMap((action) =>
      new ConsumerProfileService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
      .fetchVendorDetails(action.payload)
      .pipe(
        map((result) => {
          return ConsumerProfileActions.fetchVendorDetailsSuccess(result)
        }),
        catchError((err: HttpError) =>
          of(ConsumerProfileActions.fetchVendorDetailsFailure(err))
        )
      )
    )
  )

const registerWorkflowStepEventLoad:Epic<Action, Action, RootState> =(
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.registerWorkflowStepLoadEvent)),
    switchMap((action) => 
      new ConsumerProfileService(
        ConsumerSessionStore.getSessionToken(state$.value)
        
      )
      .registerWorkflowStepLoadEvent(action.payload.workflowStepType)
      .pipe(
        map(()=> ConsumerProfileActions.registerWorkflowStepLoadEventSuccess()),
        catchError(
          (error:HttpError) => of(
              ConsumerProfileActions.registerWorkflowStepLoadEventFailure(error)
            )
        )
      )
    )    
  )
  
export const consumerProfileEpics = [
  createConsumerProfileEpic,
  createConsumerProfileWithAssetEpic,
  setConsumerProfileEpic,
  savePreApprovalResultToConsumerProfileEpic,
  submitConsumerProfileEpic,
  setConsumerProfileAfterApplicationReassignmentEpic,
  saveViewedLoanOffersPageEpic,
  clearConsumerProfileSessionEpic,
  saveConsumerAssetSubtypeEpic,
  fetchVendorDetailsEpic,
  registerWorkflowStepEventLoad
]
