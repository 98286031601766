import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'
import { ConsumerProfileSessionInfo } from '../../../domain/consumer/consumer-profile/consumer-profile-session-token.interface'
import { map } from 'rxjs'
import { ConsumerSessionTokenDtoMapper } from '../dto/consumer-session-token/consumer-session-token-dto-mapper'
import { ConsumerSessionTokenDto } from '../dto/consumer-session-token/consumer-session-token.dto'
import { CreateConsumerProfileForm } from '../../../modules/consumer/modules/consumer-profile/types/create-consumer-profile-form.interface'
import { SubmitConsumerProfileForm } from '../../../modules/consumer/modules/consumer-profile/types/submit-consumer-profile-form.interface'
import { ConsumerPostSubmissionApprovedApplicantLink } from '../../../modules/consumer/modules/consumer-post-submission/modules/consumer-post-submission-approved/types/consumer-post-submission-approved-applicant-link.interface'
import { CreateConsumerProfileWithAssetForm } from '../../../modules/consumer/modules/consumer-profile/types/create-consumer-profile-with-asset-form.interface'
import { Vendor } from '../../../domain/vendor/vendor.interface'
import { ConsumerWorkflowStepType } from '../../../domain/consumer/consumer-workflow/consumer-workflow-step-type.enum'

export class ConsumerProfileService extends BffService {
  public createConsumerProfile(
    form: CreateConsumerProfileForm
  ): FetchResponse<ConsumerProfileSessionInfo> {
    return super
      .createRequest<ConsumerSessionTokenDto>({
        method: FetchMethod.POST,
        url: `${this.bffUrl}/v1/consumer-profile`,
        body: form
      })
      .pipe(
        map((result) =>
          new ConsumerSessionTokenDtoMapper(result).toDomainObject()
        )
      )
  }

  public createConsumerProfileWithAsset(
    form: CreateConsumerProfileWithAssetForm
  ): FetchResponse<ConsumerProfileSessionInfo> {
    return super
      .createRequest<ConsumerSessionTokenDto>({
        method: FetchMethod.POST,
        url: `${this.bffUrl}/v1/consumer-profile/create-with-asset`,
        body: form
      })
      .pipe(
        map((result) =>
          new ConsumerSessionTokenDtoMapper(result).toDomainObject()
        )
      )
  }

  public submitConsumerProfile(
    form: SubmitConsumerProfileForm
  ): FetchResponse<void> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/submit`,
      body: form
    })
  }

  public getApplicantLink(): FetchResponse<ConsumerPostSubmissionApprovedApplicantLink> {
    return super.createAuthorizedRequest<ConsumerPostSubmissionApprovedApplicantLink>(
      {
        method: FetchMethod.GET,
        url: `${this.bffUrl}/v1/consumer-profile/application/unique-link`
      }
    )
  }

  public fetchVendorDetails(vendorId: string): FetchResponse<Vendor> {
    return super.createAuthorizedRequest<Vendor>({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/product/vendor/${vendorId}`
    });
  }

  public registerWorkflowStepLoadEvent(workflowStepType:ConsumerWorkflowStepType):FetchResponse<void>{
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url:`${this.bffUrl}/v1/consumer-profile/workflow-step/event/load`,
      body : {workflowStepType: workflowStepType}
    })
  }
}
